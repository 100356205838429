import { FC } from "react";
import Icon from "../icon";

import styles from "./info.module.scss";

export interface InfoProps {
    message?: string;
    htmlFor?: string;
    classes?: string;
}

const Info: FC<InfoProps> = ({ message, htmlFor, classes }) => {
    return (
        <label htmlFor={htmlFor} className={`${styles.info} ${classes ? classes : ""}`}>
            <Icon icon={{ icon: ["fas", "info-circle"], type: "FontAwesomeIcon", size: "1x" }} classes={styles.icon} />
            {message}
        </label>
    );
};

export default Info;
