import http from "./http.services";
import { Jokers as JokersType } from "../utils/hooks/useJokers";
import * as constants from "../constants/resources.constants";

const getJokers = async (userId?: string): Promise<JokersType> => {
    const { data: jokers } = await http.get(constants.JOKERS, {
        withCredentials: true,
        params: {
            ...(userId && { userId: userId })
        }
    });
    return jokers;
};

const setJokers = async (userId: string, scoringTeamId: number, concedingTeamId: number): Promise<JokersType> => {
    const { data: jokers } = await http.post(
        constants.JOKERS,
        { userId, scoringTeamId, concedingTeamId },
        {
            withCredentials: true
        }
    );
    return jokers;
};

export const jokersService = {
    getJokers,
    setJokers
};
