import { FC, useState, useEffect, MouseEvent as ReactMouseEvent } from "react";
import useDebounce from "../../../utils/hooks/useDebounce";

import "./ripple.scss";

interface RippleType {
    left: number;
    top: number;
    width: number;
    height: number;
}

export interface RippleProps {
    rippleColor?: "black" | "white";
}

const Ripple: FC<RippleProps> = ({ rippleColor = "white" }) => {
    const [rippleList, setRippleList] = useState<RippleType[]>([]);
    const debouncedRipple = useDebounce(rippleList, 1500);

    useEffect(() => {
        clearRippleEffect();
    }, [debouncedRipple]);

    const createRippleEffect = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        const container = event.currentTarget;
        const pos = container.getBoundingClientRect();
        const size = container.offsetWidth;
        const x = event.pageX - pos.x - size / 2;
        const y = event.pageY - pos.y - size / 2;

        const ripple = { left: x, top: y, width: size, height: size };
        setRippleList([...rippleList, ripple]);
    };

    const clearRippleEffect = () => {
        setRippleList([]);
    };

    const rippleContent = (ripple: RippleType, index: number) => {
        return <span key={`span ${index}`} className={`ripple ${rippleColor}`} style={ripple} />;
    };

    return (
        <div className="ripple-container" onClick={createRippleEffect}>
            {rippleList.length > 0 &&
                rippleList.map((ripple, index) => {
                    return rippleContent(ripple, index);
                })}
        </div>
    );
};

export default Ripple;
