const getOffsetDate = (days: number): Date => {
    const date = new Date();
    const offsetDate = date.getDate() + days;
    date.setDate(offsetDate);

    return date;
};

const getDateAtEndOfDay = (date?: Date) => {
    const currentDate = date || new Date();
    currentDate?.setHours(23, 59, 59, 999);

    return currentDate;
};

const getOrdinalNum = (number: number) => {
    return (
        number +
        (31 === number || 21 === number || 1 === number
            ? "st"
            : 22 === number || 2 === number
            ? "nd"
            : 23 === number || 3 === number
            ? "rd"
            : "th")
    );
};

const formatTime = (date: string) => {
    const dateType = new Date(date);

    return `${dateType.toLocaleTimeString("en-GB", { timeZone: "UTC" }).substring(0, 5)}`;
};

const formatDate = (date: string) => {
    const dateType = new Date(date);

    return `${getOrdinalNum(dateType.getDate())} ${dateType.toLocaleString("default", {
        month: "short"
    })}`;
};

export { getOffsetDate, getDateAtEndOfDay, formatTime, formatDate, getOrdinalNum };
