import { useEffect, useState } from "react";
import { predictionsService } from "../../services/predictions.service";

export interface User {
    id: string;
    score: number;
}

export interface PredictionsLeaderboard {
    data: User[];
    success: boolean;
}

export interface UsePredictionsLeaderboard {
    predictionsLeaderboard: User[];
    loading: boolean;
    error: any;
}

const usePredictionsLeaderboard = (): UsePredictionsLeaderboard => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [predictionsLeaderboard, setPredictionsLeaderboard] = useState<User[]>([]);

    useEffect(() => {
        const getPredictionsLeaderboard = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await predictionsService.getPredictionsLeaderboard();
                if (response?.data) setPredictionsLeaderboard(response?.data);
            } catch (error) {
                setError(true);
            }
            setLoading(false);
        };

        getPredictionsLeaderboard();
    }, []);

    return { predictionsLeaderboard, loading, error };
};

export default usePredictionsLeaderboard;
