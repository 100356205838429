import { FC } from "react";
import ProgressBar from "../progressBar";
import { useProgressBar } from "../../../store/progressBar/useProgressBar";

import styles from "./pageProgressBar.module.scss";

export interface ProgressBarProps {}

const PageProgressBar: FC<ProgressBarProps> = () => {
    const { active, progress } = useProgressBar();

    return <ProgressBar active={active} progress={progress} classes={styles.progressBar}></ProgressBar>;
};

export default PageProgressBar;
