import { useEffect, useState } from "react";
import { jokersService } from "../../services/jokers.service";

export interface Joker {
    id: number;
    user_id: string;
    scoring_team_id: number;
    conceding_team_id: number;
}

export interface Jokers {
    data: Joker[];
    success: boolean;
}

export interface UseJokers {
    jokers: Joker[];
    loading: boolean;
    error: any;
}

const useJokers = (userId?: string): UseJokers => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [jokers, setJokers] = useState<Joker[]>([]);

    useEffect(() => {
        const getJokers = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await jokersService.getJokers(userId);
                if (response?.data) setJokers(response?.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };

        getJokers();
    }, [userId]);

    return { jokers, loading, error };
};

export default useJokers;
