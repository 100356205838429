import { createContext, useReducer, useContext } from "react";
import { Dispatch, State, ProgressQueueProviderProps } from "./types";
import progressQueueReducer from "./reducer";

const ProgressQueueStateContext = createContext<State | undefined>(undefined);
const ProgressQueueDispatchContext = createContext<Dispatch | undefined>(undefined);

const ProgressQueueProvider = ({ children }: ProgressQueueProviderProps) => {
    const [state, dispatch] = useReducer(progressQueueReducer, {
        items: []
    });
    return (
        <ProgressQueueStateContext.Provider value={state}>
            <ProgressQueueDispatchContext.Provider value={dispatch}>{children}</ProgressQueueDispatchContext.Provider>
        </ProgressQueueStateContext.Provider>
    );
};

const useProgressQueueState = () => {
    const context = useContext(ProgressQueueStateContext);
    if (context === undefined) {
        throw new Error("useProgressQueueState must be used within a ProgressQueueProvider");
    }
    return context;
};

const useProgressQueueDispatch = () => {
    const context = useContext(ProgressQueueDispatchContext);
    if (context === undefined) {
        throw new Error("useProgressQueueDispatch must be used within a ProgressQueueProvider");
    }
    return context;
};

export { ProgressQueueProvider, useProgressQueueState, useProgressQueueDispatch };
