import http from "./http.services";
import { User, LogOut } from "../store/auth/types";
import * as constants from "../constants/resources.constants";
import { User as UserRegister } from "../store/register/types";

const login = async (username: string, password: string): Promise<User> => {
    const { data: user } = await http.post(
        constants.LOGIN,
        {
            username,
            password
        },
        {
            withCredentials: true
        }
    );
    return user;
};

const logout = async (): Promise<LogOut> => {
    const { data: response } = await http.post(
        constants.LOG_OUT,
        {},
        {
            withCredentials: true
        }
    );
    return response;
};

const renew = async (): Promise<User> => {
    const { data: user } = await http.post(
        constants.RENEW,
        {},
        {
            withCredentials: true
        }
    );
    return user;
};

const getUserToken = async (): Promise<User> => {
    const { data: user } = await http.get(constants.VALIDATE_TOKEN, {
        withCredentials: true
    });
    return user;
};

const getUser = async (userId: string): Promise<UserRegister> => {
    const { data: user } = await http.get(constants.USER.replace("{id}", userId), {
        withCredentials: true
    });
    return user;
};

const deleteUser = async (userId: string): Promise<object> => {
    const { data: response } = await http.delete(constants.USER.replace("{id}", userId), { withCredentials: true });
    return response;
};

export const authService = {
    login,
    logout,
    renew,
    getUserToken,
    getUser,
    deleteUser
};
