import { useState } from "react";
import * as constants from "../../constants/admin.constants";
import { toast } from "react-toastify";
import { jokersService } from "../../services/jokers.service";

export interface UseSetJokers {
    setJokers: (userId?: string, scoringTeamId?: number, concedingTeamId?: number) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useSetJokers = (): UseSetJokers => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const setJokers = async (userId?: string, scoringTeamId?: number, concedingTeamId?: number) => {
        if (!userId || !scoringTeamId || !concedingTeamId) return;

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            await jokersService.setJokers(userId, scoringTeamId, concedingTeamId);
            toast.success(constants.FIXTURE_RESULT_SUCCESS);
            setSuccess(true);
        } catch (error) {
            toast.error(constants.FIXTURE_RESULT_ERROR);
            setError(error);
        }
        setLoading(false);
    };

    return { success, loading, error, setJokers };
};

export default useSetJokers;
