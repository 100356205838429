/* Set Predictions Messages */
export const SET_PREDICTIONS_SUCCESS = "Predictions successfully set";
export const SET_PREDICTIONS_ERROR = "Error setting predictions";

/* Add fixture */
export const FIXTURE_SUCCESS = "Fixture successfully added";
export const FIXTURE_ERROR = "Error adding fixture";

/* Set fixture Result */
export const FIXTURE_RESULT_SUCCESS = "Fixture successfully updated";
export const FIXTURE_RESULT_ERROR = "Error updating fixture";
