import { FC } from "react";
import { useLogin } from "../../../store/auth/useLogin";
import _ from "lodash";
import Button from "../button";

import styles from "./supportButton.module.scss";

export interface SupportButtonProps {}

const SupportButton: FC<SupportButtonProps> = () => {
    const { auth } = useLogin();

    const formatName = (userId?: string) => _.startCase(userId?.replace(/@sbgsportssoftware.com/gi, ""));

    const handleClick = () => {
        window.location.href = `mailto:rick@sbgsportssoftware.com?subject=Help!&body=Hey Rick, I'm having a few issues with the football prediction site and wondered if you could give me some help.%0D%0A%0D%0AThanks, ${formatName(
            auth.user?.email
        )}`;
    };
    return (
        <Button
            id={"supportButton"}
            name={"?"}
            onClick={handleClick}
            classes={`${styles.container} btn btn-circle-md btn-primary`}
        />
    );
};

export default SupportButton;
