import { useEffect, useState } from "react";
import { fixturesService } from "../../services/fixtures.service";
import { Type } from "./useFixture";

export interface Fixture {
    id: number;
    home_team_score: number | undefined;
    away_team_score: number | undefined;
    date: string;
    type: Type;
    home_team: {
        id: number;
        name: string;
    };
    away_team: {
        id: number;
        name: string;
    };
}

export interface Fixtures {
    data: Fixture[];
    success: boolean;
}

export interface UseFixtures {
    fixtures: Fixture[];
    loading: boolean;
    error: any;
}

const useFixtures = (): UseFixtures => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [fixtures, setFixtures] = useState<Fixture[]>([]);

    useEffect(() => {
        const getFixtures = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await fixturesService.getFixtures();
                if (response?.data) setFixtures(response?.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };

        getFixtures();
    }, []);

    return { fixtures, loading, error };
};

export default useFixtures;
