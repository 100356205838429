import { FC, useState, useEffect, FormEvent } from "react";
import Modal from "../common/modal";
import { Props as ReactModalProps } from "react-modal";
import useUserPredictions from "../../utils/hooks/useUserPredictions";
import { useLogin } from "../../store/auth/useLogin";
import styles from "./setPredictionsModal.module.scss";
import Input from "../common/input";
import Button from "../common/button";
import Title from "../common/title";
import { Prediction } from "../../utils/hooks/usePredictions";
import useSetPredictions from "../../utils/hooks/useSetPredictions";
import Info from "../common/info";
import _ from "lodash";

export interface SetPredictionsModalProps extends ReactModalProps {
    onClose: () => void;
}

const SetPredictionsModal: FC<SetPredictionsModalProps> = ({ isOpen, onClose, ...rest }) => {
    const [refresh, setRefresh] = useState(0);
    const [predictionsCopy, setPredictionsCopy] = useState<Prediction[]>([]);

    const { auth } = useLogin();
    const { setPredictions } = useSetPredictions();
    const { predictions } = useUserPredictions(auth?.user?.email, refresh);

    const currentDate = new Date();

    useEffect(() => {
        setPredictionsCopy(_.cloneDeep(predictions));
    }, [predictions]);

    const handleChange = (event: FormEvent, index: number) => {
        const target = event.target as HTMLSelectElement;
        const predictionsCopyModified = [...predictionsCopy];

        predictionsCopyModified[index].Prediction = {
            ...predictionsCopyModified[index]?.Prediction,
            [target.id]: target.value.length ? Number(target.value) : target.value
        };

        setPredictionsCopy(predictionsCopyModified);
    };

    const handleSetPenaltyWinnerChange = (event: FormEvent<EventTarget>, index: number) => {
        const target = event.currentTarget as HTMLSelectElement;
        const predictionsCopyModified = [...predictionsCopy];

        predictionsCopyModified[index].Prediction = {
            ...predictionsCopyModified[index]?.Prediction,
            penalty_winner_id: Number(target.id)
        };

        setPredictionsCopy(predictionsCopyModified);
    };

    const handleSetPredictions = () => {
        setPredictions(
            predictionsCopy
                .filter(
                    (x) =>
                        x?.Prediction &&
                        x?.Prediction?.home_team_score !== undefined &&
                        x?.Prediction?.away_team_score !== undefined
                )
                .map((x) => ({
                    home_team_score: x.Prediction?.home_team_score as number,
                    away_team_score: x.Prediction?.away_team_score as number,
                    penalty_winner_id: x.Prediction?.penalty_winner_id || x.home_team.id,
                    user_id: auth.user?.email as string,
                    fixture_id: x.id
                }))
        );

        setTimeout(() => setRefresh(refresh + 1), 100);

        onClose();
    };

    const isDraw = (homeTeamScore?: number, awayTeamScore?: number) => {
        return (
            homeTeamScore !== undefined &&
            awayTeamScore !== undefined &&
            homeTeamScore !== null &&
            awayTeamScore !== null &&
            homeTeamScore === awayTeamScore
        );
    };

    return (
        <Modal isOpen={isOpen} className={styles.modal} {...rest}>
            <div className={styles.parentContainer}>
                <div className={styles.childContainer}>
                    <Title classes={`${styles.title} title`} label={"Set Predictions"} />
                    <Info
                        message={"Predictions cannot be changed once set to prevent users copying others"}
                        classes={styles.info}
                    />
                    <div className={styles.heading}>
                        <div className={styles.fixture}>Fixtures</div>
                        <div className={styles.prediction}>Prediction</div>
                    </div>
                    {predictionsCopy.map((prediction, index) => (
                        <div key={prediction.id} className={styles.container}>
                            <div className={styles.innerContainer}>
                                <div className={styles.fixture}>
                                    {prediction.home_team.name} vs {prediction.away_team.name}
                                </div>
                                <div
                                    className={`${styles.prediction} ${styles.inputContainer} ${
                                        currentDate > new Date(prediction.date) ||
                                        (predictions[index]?.Prediction?.home_team_score !== null &&
                                            predictions?.[index].Prediction?.away_team_score !== null)
                                            ? styles.disabled
                                            : ""
                                    }`}
                                >
                                    <Input
                                        id={"home_team_score"}
                                        label={"Home"}
                                        type={"number"}
                                        size={"small"}
                                        min={0}
                                        value={
                                            prediction.Prediction?.home_team_score !== undefined &&
                                            prediction.Prediction?.home_team_score !== null
                                                ? prediction.Prediction?.home_team_score
                                                : ""
                                        }
                                        classes={styles.input}
                                        errorClasses={styles.error}
                                        onChange={(event: FormEvent) => handleChange(event, index)}
                                        outlined={true}
                                        disabled={
                                            currentDate > new Date(prediction.date) ||
                                            !(
                                                predictions[index].Prediction?.home_team_score === null ||
                                                predictions[index].Prediction?.home_team_score === undefined
                                            )
                                        }
                                    />
                                    <Input
                                        id={"away_team_score"}
                                        label={"Away"}
                                        type={"number"}
                                        size={"small"}
                                        min={0}
                                        value={
                                            prediction.Prediction?.away_team_score !== undefined &&
                                            prediction.Prediction?.away_team_score !== null
                                                ? prediction.Prediction?.away_team_score
                                                : ""
                                        }
                                        classes={styles.input}
                                        errorClasses={styles.error}
                                        onChange={(event: FormEvent) => handleChange(event, index)}
                                        outlined={true}
                                        disabled={
                                            currentDate > new Date(prediction.date) ||
                                            !(
                                                predictions[index].Prediction?.away_team_score === null ||
                                                predictions[index].Prediction?.away_team_score === undefined
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            {prediction.type !== "Group" &&
                                isDraw(
                                    prediction.Prediction?.home_team_score,
                                    prediction.Prediction?.away_team_score
                                ) && (
                                    <div className={styles.penaltySelection}>
                                        <div className={styles.fixture}></div>
                                        <div className={styles.penaltyWinner}>
                                            Penalty Winner
                                            <div className={styles.buttonGroup}>
                                                <Button
                                                    id={prediction.home_team.id?.toString()}
                                                    name={prediction.home_team.name}
                                                    classes={`btn  ${styles.button} ${
                                                        prediction.Prediction?.penalty_winner_id === undefined ||
                                                        prediction.Prediction?.penalty_winner_id === null ||
                                                        prediction.Prediction?.penalty_winner_id ===
                                                            prediction.home_team.id
                                                            ? "btn-primary"
                                                            : "btn-outline-primary"
                                                    }`}
                                                    disabled={
                                                        predictions[index]?.Prediction?.home_team_score !== null &&
                                                        predictions?.[index].Prediction?.away_team_score !== null
                                                    }
                                                    onClick={(event: FormEvent<EventTarget>) =>
                                                        handleSetPenaltyWinnerChange(event, index)
                                                    }
                                                />

                                                <Button
                                                    id={prediction.away_team.id?.toString()}
                                                    name={prediction.away_team.name}
                                                    classes={`btn ${styles.button} ${
                                                        prediction.Prediction?.penalty_winner_id ===
                                                        prediction.away_team.id
                                                            ? "btn-primary"
                                                            : "btn-outline-primary"
                                                    }`}
                                                    disabled={
                                                        predictions[index]?.Prediction?.home_team_score !== null &&
                                                        predictions?.[index].Prediction?.away_team_score !== null
                                                    }
                                                    rippleColor={"black"}
                                                    onClick={(event: FormEvent<EventTarget>) =>
                                                        handleSetPenaltyWinnerChange(event, index)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
                <div className={styles.footer}>
                    <Button
                        id={"Cancel"}
                        name={"Cancel"}
                        classes={`btn btn-outline-primary ${styles.button}`}
                        onClick={onClose}
                    />
                    <Button
                        id={"save"}
                        name={"Save"}
                        classes={`btn btn-primary ${styles.button}`}
                        onClick={handleSetPredictions}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SetPredictionsModal;
