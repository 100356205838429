import { FC } from "react";
import Login from "../../login";
import Img from "../../common/img";
import footballer from "../../../assets/images/login/footballer.svg";

import styles from "./loginPage.module.scss";

export interface LoginPageProps {}

const LoginPage: FC<LoginPageProps> = () => {
    return (
        <div className={`${styles.parentContainer}`}>
            <div className={styles.childContainer}>
                <div className={styles.heading}>
                    <div className={`title ${styles.title}`}>
                        L<span className={styles.ball} />
                        gin.
                    </div>
                    <Img src={footballer} alt={"footballer"} classes={styles.footballer} />
                </div>
                <Login />
            </div>
        </div>
    );
};

export default LoginPage;
