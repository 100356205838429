import { useEffect, useState } from "react";
import { fixturesService } from "../../services/fixtures.service";

export interface Fixtures {
    data: number;
    success: boolean;
}

export interface UseFixturesRemaining {
    fixturesRemaining: number | undefined;
    loading: boolean;
    error: any;
}

const useFixturesRemaining = (): UseFixturesRemaining => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [fixturesRemaining, setFixturesRemaining] = useState<number>();

    useEffect(() => {
        const getFixturesRemaining = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await fixturesService.getFixturesRemaining();
                if (response?.data) setFixturesRemaining(response?.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };

        getFixturesRemaining();
    }, []);

    return { fixturesRemaining, loading, error };
};

export default useFixturesRemaining;
