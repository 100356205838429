import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faFutbol,
    faUsersCog,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faExclamation,
    faCheck,
    faUpload,
    faCaretDown,
    faCaretUp,
    faEllipsisV,
    faInfoCircle,
    faSignOutAlt,
    faTimes,
    faBullseye,
    faEnvelopeOpenText,
    faArrowUp,
    faArrowDown,
    faFilter,
    faArrowLeft,
    faCompressAlt,
    faExpandAlt,
    faExchangeAlt,
    faPoll,
    faChartLine
} from "@fortawesome/free-solid-svg-icons";

import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

library.add(
    faFutbol,
    faUsersCog,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faExclamation,
    faCheck,
    faUpload,
    faCaretDown,
    faCaretUp,
    faEllipsisV,
    faInfoCircle,
    faSignOutAlt,
    faTimes,
    faTrashAlt,
    faBullseye,
    faEnvelopeOpenText,
    faArrowUp,
    faArrowDown,
    faFilter,
    faArrowLeft,
    faCompressAlt,
    faExpandAlt,
    faExchangeAlt,
    faPoll,
    faChartLine,
    faChevronDown
);
