import http from "./http.services";
import { Teams as TeamsType } from "../utils/hooks/useTeams";
import * as constants from "../constants/resources.constants";

const getTeams = async (): Promise<TeamsType> => {
    const { data: teams } = await http.get(constants.TEAMS, {
        withCredentials: true
    });
    return teams;
};

export const teamService = {
    getTeams
};
