import { FC } from "react";
import usePredictionsLeaderboard from "../../utils/hooks/usePredictionsLeaderboard";
import Title from "../common/title";
import _ from "lodash";

import styles from "./predictionsLeaderboard.module.scss";

export interface PredictionsLeaderboardProps {
    classes: string;
}

const PredictionsLeaderboard: FC<PredictionsLeaderboardProps> = ({ classes }) => {
    const { predictionsLeaderboard } = usePredictionsLeaderboard();

    const formatName = (userId?: string) => {
        let atIndex = userId?.indexOf("@")
        let names = userId?.substring(0, atIndex) 
        if (!names) {
            return userId
        }
        let dotIndex = names?.indexOf(".")
        if (dotIndex === -1) {
            return names
        }
        let plusIndex = names?.indexOf("+")
        if (plusIndex !== -1) {
            return names.substring(0, plusIndex).replace(".", " ")
        }
        return names.replace(".", " ")
    }

    return (
        <div className={`${styles.container} ${classes}`}>
            <Title label={"Leaderboard"} classes={styles.title} />
            <div className={styles.heading}>
                <div className={styles.user}>User</div>
                <div className={styles.ranking}>Ranking</div>
                <div className={styles.score}>Score</div>
            </div>
            {predictionsLeaderboard.map((user, index) => (
                <div key={`${user.id} ${index}`} className={styles.itemContainer}>
                    <div className={styles.user}>{formatName(user.id)}</div>
                    <div className={styles.ranking}>{index + 1}</div>
                    <div className={styles.score}>{user.score}</div>
                </div>
            ))}
        </div>
    );
};

export default PredictionsLeaderboard;
