import { useState } from "react";
import * as constants from "../../constants/admin.constants";
import { toast } from "react-toastify";
import { fixturesService } from "../../services/fixtures.service";

export interface UseFixtureResult {
    setFixtureResult: (fixtureId?: number, homeTeam?: number, awayTeam?: number) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useFixtureResult = (): UseFixtureResult => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const setFixtureResult = async (fixtureId?: number, homeTeamScore?: number, awayTeamScore?: number) => {
        if (fixtureId === undefined || homeTeamScore === undefined || awayTeamScore === undefined) return;

        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            await fixturesService.setFixtureResult(fixtureId, homeTeamScore, awayTeamScore);
            toast.success(constants.FIXTURE_RESULT_SUCCESS);
            setSuccess(true);
        } catch (error) {
            toast.error(constants.FIXTURE_RESULT_ERROR);
            setError(error);
        }
        setLoading(false);
    };

    return { success, loading, error, setFixtureResult };
};

export default useFixtureResult;
