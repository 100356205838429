import { FC } from "react";
import ProgressBar from "../progressBar";
import * as string from "../../../utils/string";
import * as types from "../../../store/progressQueue/types";

import styles from "./progressQueueContent.module.scss";

export interface ProgressQueueContentProps {
    items: types.Item[];
    collapsed: boolean;
}

const ProgressQueueContent: FC<ProgressQueueContentProps> = ({ items, collapsed }) => {
    return (
        <div className={`${styles.content} ${collapsed ? styles.collapsed : ""}`}>
            {items.map((item) => {
                return (
                    <div id={item.id} key={item.id} className={styles.item}>
                        <div className={styles.itemDetails}>
                            <div className={styles.name}>{item.name}</div>
                            {!!item.stats?.progress && (
                                <div className={styles.percentage}>{string.formatPercentage(item.stats?.progress)}</div>
                            )}
                            {!!item.stats?.secondsLeft && (
                                <div className={styles.secondsLeft}>{string.formatTime(item.stats?.secondsLeft)}</div>
                            )}
                        </div>
                        <ProgressBar
                            active={item.active}
                            progress={item.stats?.progress || 0}
                            classes={styles.progressBar}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ProgressQueueContent;
