import { FC, useState, FormEvent } from "react";
import AutoComplete, { Item } from "../../common/autoComplete";
import Nav from "../../common/nav";
import Title from "../../common/title";
import Input from "../../common/input";

import styles from "./adminPage.module.scss";
import useTeams from "../../../utils/hooks/useTeams";
import Button from "../../common/button";
import useFixture, { Type } from "../../../utils/hooks/useFixture";
import useFixtureResult from "../../../utils/hooks/useFixtureResult";
import useFixtures from "../../../utils/hooks/useFixtures";

export interface HomePageProps {}

const AdminPagePage: FC<HomePageProps> = () => {
    const [homeTeam, setHomeTeam] = useState<Item | null>();
    const [awayTeam, setAwayTeam] = useState<Item | null>();
    const [type, setType] = useState<Item | null>();
    const [date, setDate] = useState<string>("");

    const [selectedFixture, setSelectedFixture] = useState<Item | null>();
    const [homeTeamScore, setHomeTeamScore] = useState<number | undefined>();
    const [awayTeamScore, setAwayTeamScore] = useState<number | undefined>();

    const { teams } = useTeams();
    const { setFixture } = useFixture();
    const { fixtures } = useFixtures();
    const { setFixtureResult } = useFixtureResult();

    const types = [
        { id: "Group", name: "Group" },
        { id: "Round of 16", name: "Round of 16" },
        { id: "Quarter finals", name: "Quarter finals" },
        { id: "Semi Finals", name: "Semi Finals" },
        { id: "Finals", name: "Finals" }
    ];

    const handleHomeTeamSelection = (item: Item | null) => {
        setHomeTeam(item);
    };

    const handleAwayTeamSelection = (item: Item | null) => {
        setAwayTeam(item);
    };

    const handleTypeSelection = (item: Item | null) => {
        setType(item);
    };

    const handleDateSelection = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLSelectElement;
        setDate(target.value);
    };

    const handleFixtureSelection = (item: Item | null) => {
        setSelectedFixture(item);
    };

    const handleHomeTeamScoreSelection = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLSelectElement;
        setHomeTeamScore(Number(target.value));
    };

    const handleAwayTeamScoreSelection = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLSelectElement;
        setAwayTeamScore(Number(target.value));
    };

    const handleSetFixture = () => {
        setFixture(homeTeam?.id as number, awayTeam?.id as number, date, type?.id as Type);
    };

    const handleSetFixtureResult = () => {
        setFixtureResult(selectedFixture?.id as number | undefined, homeTeamScore, awayTeamScore);
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Nav></Nav>
                <div className={styles.content}>
                    <div className={styles.addFixtureContainer}>
                        <Title label={"Add Fixture"} classes={"subtitle"} />
                        <AutoComplete
                            id={"home team"}
                            label={"Home Team"}
                            items={teams}
                            selectedItem={homeTeam}
                            onSelect={handleHomeTeamSelection}
                        />
                        <AutoComplete
                            id={"Away team"}
                            label={"Away Team"}
                            items={teams}
                            selectedItem={awayTeam}
                            onSelect={handleAwayTeamSelection}
                        />
                        <Input
                            id={"date"}
                            label={"Date"}
                            type={"datetime-local"}
                            value={date}
                            overrideFocus={true}
                            onInput={handleDateSelection}
                            onChange={handleDateSelection}
                        />
                        <AutoComplete
                            id={"Type"}
                            label={"Type"}
                            items={types}
                            selectedItem={type}
                            onSelect={handleTypeSelection}
                        />
                        <Button
                            id={"Add Fixture"}
                            name={"Add Fixture"}
                            disabled={!(homeTeam || awayTeam || type || date)}
                            classes={"btn btn-primary"}
                            onClick={handleSetFixture}
                        />
                    </div>
                    <div className={styles.addResultContainer}>
                        <Title label={"Set Fixture Result"} classes={"subtitle"} />
                        <AutoComplete
                            id={"Fixture"}
                            label={"Fixture"}
                            items={fixtures.map((fixture) => ({
                                id: fixture.id,
                                name: `${fixture.home_team.name} vs ${fixture.away_team.name}`
                            }))}
                            selectedItem={awayTeam}
                            onSelect={handleFixtureSelection}
                        />
                        <Input
                            id={"homeTeamScore"}
                            label={"Home Team Score"}
                            type={"number"}
                            value={homeTeamScore}
                            onChange={handleHomeTeamScoreSelection}
                        />
                        <Input
                            id={"awayTeamScore"}
                            label={"Away Team Score"}
                            type={"number"}
                            value={awayTeamScore}
                            onChange={handleAwayTeamScoreSelection}
                        />
                        <Button
                            id={"Set Result"}
                            name={"Set Result"}
                            disabled={!selectedFixture || !homeTeamScore === undefined || !awayTeamScore === undefined}
                            classes={"btn btn-primary"}
                            onClick={handleSetFixtureResult}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPagePage;
