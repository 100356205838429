import http from "./http.services";
import * as constants from "../constants/resources.constants";
import { Prediction } from "../utils/hooks/useSetPredictions";

const getPredictions = async (params?: { userId?: string; group?: string }) => {
    const { data: response } = await http.get(constants.PREDICTIONS, {
        withCredentials: true,
        params: {
            ...(params?.userId && { userId: params?.userId }),
            ...(params?.group && { group: params?.group })
        }
    });
    return response;
};

const setPredictions = async (predictions: Prediction[]) => {
    const { data: response } = await http.post(
        constants.PREDICTIONS,
        { predictions },
        {
            withCredentials: true
        }
    );
    return response;
};

const getPredictionsLeaderboard = async () => {
    const { data: response } = await http.get(constants.PREDICTIONS_LEADERBOARD, {
        withCredentials: true
    });
    return response;
};

const getPredictionsSummary = async (userId: string) => {
    const { data: response } = await http.get(constants.PREDICTIONS_SUMMARY.replace("{userId}", userId), {
        withCredentials: true
    });
    return response;
};

export const predictionsService = {
    getPredictions,
    setPredictions,
    getPredictionsLeaderboard,
    getPredictionsSummary
};
