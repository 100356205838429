import { FC, useEffect } from "react";
import { useLogin } from "../../../store/auth/useLogin";
import PreloadPage from "../preloadPage";

export interface LogoutPageProps {}

const LogoutPage: FC<LogoutPageProps> = () => {
    const { logout } = useLogin();

    useEffect(() => {
        logout();
    }, [logout]);

    return <PreloadPage />;
};

export default LogoutPage;
