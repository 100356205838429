const getScore = (
    homeTeamId?: number,
    awayTeamId?: number,
    homeTeamScorePrediction?: number,
    awayTeamScorePrediction?: number,
    homeTeamScore?: number,
    awayTeamScore?: number,
    jokerScoringTeamId?: number,
    jokerConcedingTeamId?: number,
    type?: string,
    penaltyWinnerId?: number,
    penaltyWinnerPredictionId?: number
) => {
    if (
        homeTeamScorePrediction === undefined ||
        homeTeamScorePrediction === null ||
        awayTeamScorePrediction === undefined ||
        awayTeamScorePrediction === null ||
        homeTeamScore === null ||
        homeTeamScore === undefined ||
        awayTeamScore === null ||
        awayTeamScore === undefined) {
        return "-";
    }
    let points = 0;
    const matchWasDraw = homeTeamScore === awayTeamScore;
    const wentToPenalties = matchWasDraw && type !== "Group";
    const predictedExactScore = homeTeamScorePrediction === homeTeamScore && awayTeamScorePrediction === awayTeamScore;
    const predictedDraw = homeTeamScorePrediction === awayTeamScorePrediction
    const predictedPenaltyWinner = penaltyWinnerPredictionId !== null && penaltyWinnerPredictionId !== undefined
    const predictedCorrectPenaltyWinner = predictedPenaltyWinner && penaltyWinnerPredictionId === penaltyWinnerId
    const homeTeamWon = homeTeamScore > awayTeamScore;
    const awayTeamWon = awayTeamScore > homeTeamScore;
    const predictedHomeTeamWon = homeTeamScorePrediction > awayTeamScorePrediction && homeTeamWon && !matchWasDraw;
    const predictedAwayTeamWon = awayTeamScorePrediction > homeTeamScorePrediction && awayTeamWon && !matchWasDraw;
    let goalDifference = homeTeamScore - awayTeamScore;
    let predictedGoalDifference = homeTeamScorePrediction - awayTeamScorePrediction;
    if (goalDifference < 0) {
        goalDifference *= -1;
    }
    if (predictedGoalDifference < 0) {
        predictedGoalDifference *= -1;
    }
    //Handle when the match was a draw
    if (matchWasDraw) {
        if (predictedExactScore) {
            points += 3; //Predicted the exact score
        }
        else if (predictedDraw) {
            points += 2; //Predicted a draw, but not the exact one
        }
        if (wentToPenalties) {
            if (predictedExactScore && predictedCorrectPenaltyWinner) {
                if (points == 3) {
                    points += 1; //Extra point for predicting correct penalty winner with correct score
                }
            }
            if (predictedExactScore && !predictedCorrectPenaltyWinner) {
                if (points == 0) {
                    points += 3; //Predicted score but the wrong winner
                }
            }
            if (!predictedExactScore && predictedDraw && predictedCorrectPenaltyWinner) {
                if (points == 0) {
                    points += 3; //Wrong score but predicted draw and winner
                }
            }
        }
    } else { // Match did not draw
        if (predictedExactScore) {
            points += 3;
        } else {
            if (homeTeamWon && predictedHomeTeamWon) {
                points += 1; //Predicted home team won
                if (homeTeamScorePrediction === homeTeamScore || awayTeamScorePrediction == awayTeamScore) {
                    points += 1; //Predicted correct score of either side and winner
                } else if (goalDifference === predictedGoalDifference) {
                    points += 1; //Predicted winner and goal difference
                }
            }
            if (awayTeamWon && predictedAwayTeamWon) {
                points += 1; //Predicted away team won
                if (homeTeamScorePrediction === homeTeamScore || awayTeamScorePrediction == awayTeamScore) {
                    points += 1; //Predicted correct score and winner
                } else if (goalDifference === predictedGoalDifference) {
                    points += 1; //Predicted winner and goal difference
                }
            }
        }
    }
    //Apply jokers at the end
    if (homeTeamId === jokerScoringTeamId && homeTeamScore !== undefined && homeTeamScore !== null) {
        points += homeTeamScore;
    }
    if (awayTeamId === jokerScoringTeamId && awayTeamScore !== undefined && awayTeamScore !== null) {
        points += awayTeamScore;
    }
    if (homeTeamId === jokerConcedingTeamId && awayTeamScore !== undefined && awayTeamScore !== null) {
        points += awayTeamScore;
    }
    if (awayTeamId === jokerConcedingTeamId && homeTeamScore !== undefined && homeTeamScore !== null) {
        points += homeTeamScore;
    }
    return points;
};

export { getScore };
