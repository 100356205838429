import { FC, Fragment } from "react";
import { useLogin } from "../../store/auth/useLogin";
import useJokers from "../../utils/hooks/useJokers";
import useUserPredictions from "../../utils/hooks/useUserPredictions";
import Button from "../common/button";
import Title from "../common/title";
import { getScore } from "../../utils/score";
import { formatDate, formatTime } from "../../utils/date";

import styles from "./userPredictions.module.scss";

export interface UserPredictinsProps {
    onOpenJokersModal: () => void;
    onOpenPredictionsModal: () => void;
    classes: string;
}

const UserPredictins: FC<UserPredictinsProps> = ({ classes, onOpenJokersModal, onOpenPredictionsModal }) => {
    const { auth } = useLogin();
    const { jokers } = useJokers(auth.user?.email);
    const { predictions } = useUserPredictions(auth?.user?.email);

    const getPrediction = (homeTeamScore?: number, awayTeamScore?: number) => {
        if (
            homeTeamScore !== undefined &&
            homeTeamScore !== null &&
            awayTeamScore !== undefined &&
            awayTeamScore !== null
        ) {
            return (
                <Fragment>
                    {homeTeamScore} : {awayTeamScore}
                </Fragment>
            );
        }

        return <Fragment>-</Fragment>;
    };

    const getResult = (homeTeamScore?: number, awayTeamScore?: number) => {
        if (
            homeTeamScore !== undefined &&
            homeTeamScore !== null &&
            awayTeamScore !== undefined &&
            awayTeamScore !== null
        ) {
            return (
                <Fragment>
                    {homeTeamScore} : {awayTeamScore}
                </Fragment>
            );
        }
        return <Fragment>-</Fragment>;
    };

    return (
        <div className={`${styles.container} ${classes}`}>
            <div className={styles.titleContainer}>
                <Title label={"Your Predictions"} classes={styles.title} />
                <div className={styles.buttonGroup}>
                    <Button
                        name={"Set Jokers"}
                        classes={`btn btn-outline-primary ${styles.setJokerButton}`}
                        onClick={onOpenJokersModal}
                    />
                    <Button
                        name={"Set Predictions"}
                        classes={`btn btn-primary ${styles.setPredictionsButton}`}
                        onClick={onOpenPredictionsModal}
                    />
                </div>
            </div>
            <div className={styles.heading}>
                <div className={styles.fixture}>Fixtures</div>
                <div className={styles.date}>Kick off</div>
                <div className={styles.prediction}>Prediction</div>
                <div className={styles.result}>Result</div>
                <div className={styles.score}>Score</div>
            </div>
            {predictions?.map((prediction, index) => (
                <div key={`${prediction.id} ${index}`} className={styles.itemContainer}>
                    <div className={styles.fixture}>
                        {prediction.home_team.name} vs {prediction.away_team.name}
                    </div>
                    <div className={styles.date}>
                        <div>{formatTime(prediction.date)}</div>
                        <div>{formatDate(prediction.date)}</div>
                    </div>
                    <div className={styles.prediction}>
                        {getPrediction(prediction.Prediction?.home_team_score, prediction.Prediction?.away_team_score)}
                    </div>
                    <div className={styles.result}>
                        {getResult(prediction?.home_team_score, prediction?.away_team_score)}
                    </div>
                    <div className={styles.score}>
                        {getScore(
                            prediction?.home_team.id,
                            prediction?.away_team.id,
                            prediction?.Prediction?.home_team_score,
                            prediction?.Prediction?.away_team_score,
                            prediction?.home_team_score,
                            prediction?.away_team_score,
                            jokers?.[0]?.scoring_team_id,
                            jokers?.[0]?.conceding_team_id,
                            prediction.type,
                            prediction.penalty_winner_id,
                            prediction.Prediction?.penalty_winner_id
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UserPredictins;
