import { useEffect, useState } from "react";
import { predictionsService } from "../../services/predictions.service";
import { Type } from "./useFixture";

export interface Prediction {
    id: number;
    home_team_score: number | undefined;
    away_team_score: number | undefined;
    penalty_winner_id?: number;
    date: string;
    type: Type;
    home_team: {
        id: number;
        name: string;
    };
    away_team: {
        id: number;
        name: string;
    };
    Prediction?: {
        id?: number;
        home_team_score?: number;
        away_team_score?: number;
        penalty_winner_id?: number;
        fixture_id?: number;
        user_id?: string;
        Joker?: {
            id: number;
            user_id: string;
            scoring_team_id: number;
            conceding_team_id: number;
        };
    };
}

export interface Predictions {
    data: Prediction[];
    succes: boolean;
}

export interface UsePredictions {
    predictions: Prediction[];
    loading: boolean;
    error: any;
}

const usePredictions = (): UsePredictions => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [predictions, setPredictions] = useState<Prediction[]>([]);

    useEffect(() => {
        const getPredictions = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await predictionsService.getPredictions();
                if (response?.data) setPredictions(response?.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };

        getPredictions();
    }, []);

    return { predictions, loading, error };
};

export default usePredictions;
