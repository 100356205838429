import http from "./http.services";
import * as constants from "../constants/resources.constants";
import { Type } from "../utils/hooks/useFixture";

const getFixtures = async () => {
    const { data: response } = await http.get(constants.FIXTURES, {
        withCredentials: true
    });
    return response;
};

const getFixturesRemaining = async () => {
    const { data: response } = await http.get(constants.FIXTURES_REMAINING, {
        withCredentials: true
    });
    return response;
};

const setFixture = async (homeTeam: number, awayTeam: number, date: string, type: Type) => {
    const { data: response } = await http.post(
        constants.FIXTURES,
        { homeTeam, awayTeam, date, type },
        {
            withCredentials: true
        }
    );
    return response;
};

const setFixtureResult = async (fixtureId: number, homeTeamScore: number, awayTeamScore: number) => {
    const { data: response } = await http.post(
        constants.FIXTURES_RESULT,
        { fixtureId, homeTeamScore, awayTeamScore },
        {
            withCredentials: true
        }
    );
    return response;
};

export const fixturesService = {
    getFixtures,
    setFixture,
    getFixturesRemaining,
    setFixtureResult
};
