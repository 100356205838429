import { FC, Fragment } from "react";
import usePredictions from "../../../utils/hooks/usePredictions";
import Nav from "../../common/nav";
import _ from "lodash";
import usePredictionsLeaderboard from "../../../utils/hooks/usePredictionsLeaderboard";
import useFixtures from "../../../utils/hooks/useFixtures";
import { getScore } from "../../../utils/score";

import styles from "./predictionsTablePage.module.scss";
import { useLogin } from "../../../store/auth/useLogin";

export interface PredictionsTablePageProps {}

const PredictionsTablePage: FC<PredictionsTablePageProps> = () => {
    const { predictions } = usePredictions();
    const { auth } = useLogin();
    const { fixtures } = useFixtures();
    const { predictionsLeaderboard } = usePredictionsLeaderboard();

    const formatName = (userId?: string) => {
        let atIndex = userId?.indexOf("@") 
        let names = userId?.substring(0, atIndex) 
        if (!names) {
            return userId
        }
        let dotIndex = names?.indexOf(".")
        if (dotIndex === -1) {
            return names
        }
        let plusIndex = names?.indexOf("+")
        if (plusIndex !== -1) {
            return names.substring(0, plusIndex).replace(".", " ")
        }
        return names.replace(".", " ")
    }

    const getPrediction = (
        homeTeamId?: number,
        awayTeamId?: number,
        homeTeamScorePrediction?: number,
        awayTeamScorePrediction?: number,
        homeTeamScore?: number,
        awayTeamScore?: number,
        jokerScoringTeamId?: number,
        jokerConcedingTeamId?: number,
        hasPrediction?: boolean,
        type?: string,
        penaltyWinnerId?: number,
        penaltyWinnerPredictionId?: number
    ) => {
        if (
            homeTeamScorePrediction !== undefined &&
            homeTeamScorePrediction !== null &&
            awayTeamScorePrediction !== undefined &&
            awayTeamScorePrediction !== null &&
            hasPrediction
        ) {
            return (
                <Fragment>
                    {homeTeamScorePrediction} : {awayTeamScorePrediction}
                    <span className={styles.score}>
                        {homeTeamScore !== null &&
                            homeTeamScore !== undefined &&
                            awayTeamScore !== null &&
                            awayTeamScore !== undefined && (
                                <Fragment>
                                    (
                                    {getScore(
                                        homeTeamId,
                                        awayTeamId,
                                        homeTeamScorePrediction,
                                        awayTeamScorePrediction,
                                        homeTeamScore,
                                        awayTeamScore,
                                        jokerScoringTeamId,
                                        jokerConcedingTeamId,
                                        type,
                                        penaltyWinnerId,
                                        penaltyWinnerPredictionId
                                    )}
                                    )
                                </Fragment>
                            )}
                    </span>
                </Fragment>
            );
        }

        return <Fragment>-</Fragment>;
    };

    const getValue = (fixtureId: number, userId: string) => {
        const prediction = predictions.find(
            (prediction) => prediction.Prediction?.fixture_id === fixtureId && prediction.Prediction?.user_id === userId
        );

        const userPrediction = predictions.find(
            (prediction) =>
                prediction.Prediction?.fixture_id === fixtureId && prediction.Prediction?.user_id === auth.user?.email
        );

        return getPrediction(
            prediction?.home_team?.id,
            prediction?.away_team?.id,
            prediction?.Prediction?.home_team_score,
            prediction?.Prediction?.away_team_score,
            prediction?.home_team_score,
            prediction?.away_team_score,
            prediction?.Prediction?.Joker?.scoring_team_id,
            prediction?.Prediction?.Joker?.conceding_team_id,
            !!userPrediction,
            prediction?.type,
            prediction?.penalty_winner_id,
            prediction?.Prediction?.penalty_winner_id
        );
    };

    const getResult = (homeTeamScore?: number, awayTeamScore?: number) => {
        if (
            homeTeamScore !== undefined &&
            homeTeamScore !== null &&
            awayTeamScore !== undefined &&
            awayTeamScore !== null
        ) {
            return (
                <Fragment>
                    {homeTeamScore} : {awayTeamScore}
                </Fragment>
            );
        }

        return <Fragment> - </Fragment>;
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Nav classes={styles.nav}></Nav>
                <div className={styles.parentContent}>
                    <div className={styles.content}>
                        <div className={styles.headingContainer}>
                            <div className={styles.sticky}>
                                <div className={styles.fixture}>Fixture</div>
                                <div className={styles.round}>Round</div>
                                <div className={styles.result}>Result</div>
                            </div>
                            {predictionsLeaderboard.map((user) => (
                                <div key={`heading-${user.id}`} className={styles.user}>
                                    <div className={styles.userItem}>
                                        {formatName(user?.id)} ({user.score})
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={styles.tracks}>
                            <div className={styles.sticky}>
                                <div className={`${styles.track}`}>
                                    {fixtures.map((fixture) => (
                                        <div
                                            key={`fixture${fixture.id}`}
                                            className={`${styles.fixture} ${styles.entry}`}
                                        >
                                            {fixture.home_team.name} vs {fixture.away_team.name}
                                        </div>
                                    ))}
                                </div>
                                <div className={`${styles.track} ${styles.round}`}>
                                    {fixtures.map((fixture) => (
                                        <div
                                            key={`fixture-type-${fixture.id}`}
                                            className={`${styles.round} ${styles.entry}`}
                                        >
                                            {fixture.type}
                                        </div>
                                    ))}
                                </div>
                                <div className={`${styles.track}`}>
                                    {fixtures.map((fixture) => (
                                        <div
                                            key={`fixture-result-${fixture.id}`}
                                            className={`${styles.result} ${styles.entry}`}
                                        >
                                            {getResult(fixture.home_team_score, fixture.away_team_score)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {predictionsLeaderboard.map((user) => (
                                <div key={`track-${user.id}`} className={`${styles.track}`}>
                                    {fixtures.map((fixture) => (
                                        <div
                                            key={`${user.id}-${fixture.id}`}
                                            className={`${styles.entry} ${styles.user}`}
                                        >
                                            <div className={styles.userItem}>{getValue(fixture.id, user.id)}</div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PredictionsTablePage;
