/* SBG Hub */
export const SBG_HUB = "SBG Hub";

/* Nav Items */
export const NAV_ITEMS = {
    Predictions: "Predictions",
    PredictionsTable: "Table",
    ADMIN: "Admin",
    LOG_OUT: "Log out"
};

/* Drop Zone */
export const CHOOSE_YOUR_FILE = "Choose Your File";

/* Confirmation */
export const ARE_YOU_SURE = "Are you Sure?";
export const CONFIRM = "Confirm";

/* Cancel or Save */
export const CANCEL = "Cancel";
export const SAVE = "Save";

/* yes or No */
export const YES = "Yes";
export const NO = "No";

/* Continue */
export const CONTINUE = "Continue";

/* Modal */
export const MINIMISE = "Minimise";
export const CLOSE = "Close";

/* Controls */
export const REMOVE = "Remove";
export const DELETE = "Delete";
export const EXPAND = "Expand";
export const COLLAPSE = "Collapse";
export const EXIT = "Exit";
export const BACK = "Back";

/* Date */
export const NTH = { st: "st", nd: "nd", rd: "rd", th: "th" };

/* Upload */
export const UPLOAD = "Upload";

/* Progress */
export const PROGRESS_QUEUE = "Progress Queue";
export const ITEMS_IN_PROGRESS = "{items} in progress";

/* Time left */
export const CALCULATING_TIME_LEFT = "Calculating time...";
export const SECONDS_LEFT = "{seconds} seconds left";
export const MINUTES_LEFT = "{minutes} minutes left";
export const HOURS_LEFT = "{hours} hours left";

/* Quit page warning */
export const EXIT_WARNING = "Are you sure you want to cancel all queued items?";
