import { useState } from "react";
import * as constants from "../../constants/admin.constants";
import { toast } from "react-toastify";
import { fixturesService } from "../../services/fixtures.service";

export type Type = "Group" | "Round of 16" | "Quarter finals" | "Semi Finals" | "Finals";

export interface UseFixture {
    setFixture: (homeTeam: number, awayTeam: number, date: string, type: Type) => void;
    loading: boolean;
    success: boolean;
    error: any;
}

const useFixture = (): UseFixture => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const setFixture = async (homeTeam: number, awayTeam: number, date: string, type: Type) => {
        setLoading(true);
        setSuccess(false);
        setError(false);

        try {
            await fixturesService.setFixture(homeTeam, awayTeam, date, type);
            toast.success(constants.FIXTURE_SUCCESS);
            setSuccess(true);
        } catch (error) {
            toast.error(constants.FIXTURE_ERROR);
            setError(error);
        }
        setLoading(false);
    };

    return { success, loading, error, setFixture };
};

export default useFixture;
