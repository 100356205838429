import LoginPage from "../../pages/loginPage";
import LogoutPage from "../../pages/logoutPage";
import PredictionsPage from "../../pages/predictionsPage";
import PredictionsTablePage from "../../pages/predictionsTablePage";
import AdminPage from "../../pages/adminPage";
import { history } from "../../../utils/history";
import { Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "../protectedRoute";

const createRoutes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={"/login"} component={LoginPage} />
                <Route exact path={"/log-out"} component={LogoutPage} />
                <ProtectedRoute path={"/predictions"} exact={true} component={PredictionsPage} />
                <ProtectedRoute path={"/table"} exact={true} component={PredictionsTablePage} />
                <ProtectedRoute path={"/admin"} exact={true} component={AdminPage} />
                <ProtectedRoute path={"/"} exact={true} redirect={"/predictions"} component={PredictionsPage} />
            </Switch>
        </Router>
    );
};

export default createRoutes;
