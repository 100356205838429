import { FC } from "react";
import Icon from "../common/icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import styles from "./predictionsHeading.module.scss";

export interface PredictionsHeadingProps {
    icon: IconProp;
    label: string;
    value: string | number | undefined;
    classes?: string;
}

const PredictionsHeading: FC<PredictionsHeadingProps> = ({ icon, label, value, classes }) => {
    return (
        <div className={`${styles.container} ${classes}`}>
            <div className={styles.labelContainer}>
                <Icon icon={{ icon: icon, type: "FontAwesomeIcon", size: "2x" }} classes={styles.icon} />
                <div className={styles.label}>{label}</div>
            </div>
            <div className={styles.result}>{value}</div>
        </div>
    );
};

export default PredictionsHeading;
