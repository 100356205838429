import { useEffect, useState } from "react";
import { predictionsService } from "../../services/predictions.service";
import { Prediction } from "./usePredictions";

export interface Predictions {
    data: Prediction[];
    succes: boolean;
}

export interface UseUserPredictions {
    predictions: Prediction[];
    loading: boolean;
    error: any;
}

const useUserPredictions = (userId?: string, refresh?: number): UseUserPredictions => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [predictions, setPredictions] = useState<Prediction[]>([]);

    useEffect(() => {
        const getPredictions = async () => {
            setLoading(true);
            setError(false);

            if (!userId) return;

            try {
                const response = await predictionsService.getPredictions({ userId });
                if (response?.data) setPredictions(response?.data);
            } catch (error) {
                setError(true);
            }
            setLoading(false);
        };

        getPredictions();
    }, [userId, refresh]);

    return { predictions, loading, error };
};

export default useUserPredictions;
