import { FC } from "react";
import AutoCompleteTemplate from "@sbgsportssoftware/auto-complete";
import { AutoCompleteProps as AutoCompleteTemplateProps, Item as ItemTemplate } from "@sbgsportssoftware/auto-complete";

export interface AutoCompleteProps extends AutoCompleteTemplateProps {}
export interface Item extends ItemTemplate {}

const AutoComplete: FC<AutoCompleteProps> = ({ ...rest }) => {
    return <AutoCompleteTemplate {...rest} />;
};

export default AutoComplete;
