import { forwardRef } from "react";
import ButtonTemplate from "@sbgsportssoftware/button";
import { ButtonProps as ButtonTemplateProps } from "@sbgsportssoftware/button";
import { mapClasses } from "@sbgsportssoftware/util-styles";

import styles from "./button.module.scss";

export interface ButtonProps extends ButtonTemplateProps {}

const Button = forwardRef<HTMLButtonElement, ButtonTemplateProps>(({ classes = "", ...rest }, ref) => {
    return <ButtonTemplate classes={mapClasses(classes, styles)} ref={ref} {...rest} />;
});

export default Button;
