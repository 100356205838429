import { FC, useState, useEffect } from "react";
import Modal from "../common/modal";
import { Props as ReactModalProps } from "react-modal";
import styles from "./setJokersModal.module.scss";
import Button from "../common/button";
import Title from "../common/title";
import AutoComplete from "@sbgsportssoftware/auto-complete";
import useTeams from "../../utils/hooks/useTeams";
import { Item } from "../common/autoComplete";
import useJokers from "../../utils/hooks/useJokers";
import { useLogin } from "../../store/auth/useLogin";
import useSetJokers from "../../utils/hooks/useSetJokers";

export interface SetJokersModalProps extends ReactModalProps {
    onClose: () => void;
}

const SetJokersModal: FC<SetJokersModalProps> = ({ isOpen, onClose, ...rest }) => {
    const { teams } = useTeams();
    const { auth } = useLogin();
    const { jokers } = useJokers(auth.user?.email);
    const { setJokers } = useSetJokers();

    const currentDate = new Date();
    const competitionDate = new Date("2024-06-14T17:00:00");

    const [scoringJoker, setScoringJoker] = useState<Item | null>(null);
    const [concedingJoker, setConcedingJoker] = useState<Item | null>(null);

    useEffect(() => {
        if (!jokers.length || !teams.length) return;

        setScoringJoker(teams.find((team) => team.id === jokers?.[0]?.scoring_team_id) || null);
        setConcedingJoker(teams.find((team) => team.id === jokers?.[0]?.conceding_team_id) || null);
    }, [teams, jokers]);

    const handleSetScoringJoker = (item: Item | null) => {
        setScoringJoker(item);
    };

    const handleSetConcedingJoker = (item: Item | null) => {
        setConcedingJoker(item);
    };

    const handleSave = () => {
        setJokers(auth.user?.email, scoringJoker?.id as number, concedingJoker?.id as number);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} className={styles.modal} {...rest}>
            <div className={styles.parentContainer}>
                <div className={styles.childContainer}>
                    <Title classes={`${styles.title} title`} label={"Set Jokers"} />
                    <div className={styles.description}>
                        You get a point for every goal scored by your scoring joker, or a goal conceded by your
                        conceding joker.
                    </div>
                    <div
                        className={`${styles.inputsContainer} ${competitionDate < currentDate ? styles.disabled : ""}`}
                    >
                        <AutoComplete
                            id={"Scoring Joker"}
                            label={"Scoring Joker"}
                            items={teams}
                            selectedItem={scoringJoker}
                            outlined={true}
                            onSelect={handleSetScoringJoker}
                            classes={styles.autoComplete}
                            disabled={competitionDate < currentDate}
                        />
                        <AutoComplete
                            id={"Scoring Joker"}
                            label={"Conceding Joker"}
                            items={teams}
                            selectedItem={concedingJoker}
                            outlined={true}
                            onSelect={handleSetConcedingJoker}
                            classes={styles.autoComplete}
                            disabled={competitionDate < currentDate}
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        id={"Cancel"}
                        name={"Cancel"}
                        classes={`btn btn-outline-primary ${styles.button}`}
                        onClick={onClose}
                    />
                    <Button
                        id={"save"}
                        name={"Save"}
                        classes={`btn btn-primary ${styles.button}`}
                        onClick={handleSave}
                        disabled={!scoringJoker || !concedingJoker}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SetJokersModal;
