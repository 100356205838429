import { useState } from "react";
import { toast } from "react-toastify";
import { predictionsService } from "../../services/predictions.service";
import * as constants from "../../constants/admin.constants";

export interface Prediction {
    home_team_score: number;
    away_team_score: number;
    fixture_id: number;
    user_id: string;
}

export interface Predictions {
    succes: boolean;
}

export interface UseSetPredictions {
    setPredictions(predictions: Prediction[]): void;
    success: boolean;
    loading: boolean;
    error: any;
}

const useSetPredictions = (): UseSetPredictions => {
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(true);
    const [error, setError] = useState(false);

    const setPredictions = async (predictions: Prediction[]) => {
        setLoading(true);
        setSuccess(false);
        setError(false);
        console.log("Set predictions: ", predictions)
        try {
            await predictionsService.setPredictions(predictions);
            toast.success(constants.SET_PREDICTIONS_SUCCESS);
            setSuccess(true);
        } catch (error) {
            console.log("Error settings predictions: ", error)
            toast.error(constants.SET_PREDICTIONS_ERROR);
            setError(true);
        }
        setLoading(false);
    };

    return { success, loading, error, setPredictions };
};

export default useSetPredictions;
