import { FC } from "react";

import styles from "./preloadPage.module.scss";

export interface PreloadPageProps {}

const PreloadPage: FC<PreloadPageProps> = () => {
    return <div className={styles.parentContainer}></div>;
};

export default PreloadPage;
