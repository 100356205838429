import { FC, Fragment, useState } from "react";

import ProgressQueueHeader from "../progressQueueHeader";
import ProgressQueueContent from "../progressQueueContent";
import { useProgressQueue } from "../../../store/progressQueue/useProgressQueue";

import styles from "./progressQueue.module.scss";

export interface ProgressQueueProps {}

const ProgressQueue: FC<ProgressQueueProps> = () => {
    const { items, clearQueue } = useProgressQueue();
    const [collapsed, setCollapsed] = useState(false);

    const handleCollapsed = () => setCollapsed(!collapsed);

    const handleClose = () => {
        setCollapsed(false);
        clearQueue();
    };

    return (
        <Fragment>
            {items.length > 0 && (
                <div className={`${styles.parentContainer} ${collapsed ? styles.collapsed : ""} `}>
                    <div className={styles.childContainer}>
                        <ProgressQueueHeader
                            items={items}
                            collapsed={collapsed}
                            onCollapse={handleCollapsed}
                            onClose={handleClose}
                        />
                        <ProgressQueueContent items={items} collapsed={collapsed} />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ProgressQueue;
