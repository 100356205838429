import { useEffect, useState } from "react";
import { teamService } from "../../services/teams.service";

export interface Team {
    id: number;
    name: string;
}

export interface Teams {
    data: Team[];
    success: boolean;
}

export interface UseTeams {
    teams: Team[];
    loading: boolean;
    error: any;
}

const useTeams = (): UseTeams => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [teams, setTeams] = useState<Team[]>([]);

    useEffect(() => {
        const getTeams = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await teamService.getTeams();
                if (response?.data) setTeams(response?.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };

        getTeams();
    }, []);

    return { teams, loading, error };
};

export default useTeams;
