/* ----- Determine environment -----*/
export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

/* ----- API Resources -----*/
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

/* Auth */
export const REGISTER = BASE_URL + "/auth/register";
export const LOGIN = BASE_URL + "/auth/login";
export const LOG_OUT = BASE_URL + "/auth/logout";
export const RENEW = BASE_URL + "/auth/refresh";
export const SET_PASSWORD = BASE_URL + "/auth/reset";
export const FORGOT_PASSWORD = BASE_URL + "/auth/reset/request";
export const VALIDATE_TOKEN = BASE_URL + "/auth/validate";

/* Fixtures */
export const FIXTURES = BASE_URL + "/fixtures";
export const FIXTURE = BASE_URL + "/fixtures/{id}";
export const FIXTURES_RESULT = BASE_URL + "/fixtures/result";
export const FIXTURES_REMAINING = BASE_URL + "/fixtures/remaining";

/* Teams */
export const TEAMS = BASE_URL + "/teams";

/* Filters */
export const FILTERS = BASE_URL + "/filters";

/* Jokers */
export const JOKERS = BASE_URL + "/jokers";

/* Files */
export const FILES = BASE_URL + "/files";
export const FILE = BASE_URL + "/files/{id}";
export const PREVIEW_THUMBNAILS = FILE + "/thumbnails";
export const LARGE_PREVIEW_THUMBNAILS = FILE + "/large_thumbnails";
export const FILE_DOWNLOAD = FILE + "/download?redirect=0";
export const SIGNED_LINK = FILE + "/signed_link";
export const SYNC = FILE + "/sync";
export const CREATECLIP = FILE + "/make_clip";

/* Users */
export const USERS = BASE_URL + "/users";
export const USER = BASE_URL + "/users/{id}";

/* Predictions */
export const PREDICTIONS = BASE_URL + "/predictions";
export const PREDICTIONS_SUMMARY = BASE_URL + "/predictions/summary/{userId}";
export const PREDICTIONS_LEADERBOARD = BASE_URL + "/predictions/leaderboard";

/* ----- CDN Resources ----- */
const CDN_BASEURL = process.env.REACT_APP_CDN_BASE_URL;

export const ICONS = CDN_BASEURL + "/hubPlayer.svg";
export const BLANK_VIDEO = CDN_BASEURL + "/blank.mp4";

/* ----- AWS Resources ----- */
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_KEY = process.env.REACT_APP_AWS_KEY;
export const AWS_URL = process.env.REACT_APP_AWS_URL;
export const AWS_BUCKET = process.env.REACT_APP_BUCKET;
export const AWS_SIGNED_URL = process.env.REACT_APP_SIGNED_URL;
