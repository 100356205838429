import { FC, useState } from "react";
import useFixturesRemaining from "../../../utils/hooks/useFixturesRemaining";
import Nav from "../../common/nav";
import SupportButton from "../../common/supportButton";
import PredictionsHeading from "../../predictionsHeading";
import PredictionsLeaderboard from "../../predictionsLeaderboard";
import SetPredictionsModal from "../../setPredictionsModal";
import SetJokersModal from "../../setJokersModal";
import UserPredictins from "../../userPredictions";

import styles from "./predictionsPage.module.scss";
import usePredictionsSummary from "../../../utils/hooks/usePredictionsSummary";
import { useLogin } from "../../../store/auth/useLogin";

export interface PredictionsPageProps {}

const PredictionsPage: FC<PredictionsPageProps> = () => {
    const [jokersModalIsOpen, setJokersModalIsOpen] = useState(false);
    const [predictionsModalIsOpen, setPredictionsModalIsOpen] = useState(false);
    const { auth } = useLogin();
    const { fixturesRemaining } = useFixturesRemaining();
    const { predictionsSummary } = usePredictionsSummary(auth.user?.email);

    const onOpenJokersModal = () => setJokersModalIsOpen(true);
    const onOpenPredictionsModal = () => setPredictionsModalIsOpen(true);

    const onCloseJokersModal = () => setJokersModalIsOpen(false);
    const onClosePredictionsModal = () => setPredictionsModalIsOpen(false);

    return (
        <div className={styles.parentContainer}>
            <div className={styles.childContainer}>
                <Nav></Nav>
                <SetPredictionsModal
                    isOpen={predictionsModalIsOpen}
                    onRequestClose={onClosePredictionsModal}
                    onClose={onClosePredictionsModal}
                />
                <SetJokersModal
                    isOpen={jokersModalIsOpen}
                    onRequestClose={onCloseJokersModal}
                    onClose={onCloseJokersModal}
                />
                <SupportButton />
                <div className={styles.content}>
                    <div className={styles.leftContent}>
                        <div className={styles.headingContainer}>
                            <PredictionsHeading
                                icon={["fas", "futbol"]}
                                label={"Fixtures Remaining"}
                                value={fixturesRemaining}
                                classes={styles.predictionHeading}
                            />
                            <PredictionsHeading
                                icon={["fas", "poll"]}
                                label={"Current Position"}
                                value={predictionsSummary?.currentPosition}
                                classes={styles.predictionHeading}
                            />
                            <PredictionsHeading
                                icon={["fas", "chart-line"]}
                                label={"Average Score"}
                                value={predictionsSummary?.averageScore || "-"}
                                classes={styles.predictionHeading}
                            />
                        </div>
                        <UserPredictins
                            classes={styles.userPredictions}
                            onOpenJokersModal={onOpenJokersModal}
                            onOpenPredictionsModal={onOpenPredictionsModal}
                        />
                    </div>
                    <PredictionsLeaderboard classes={styles.predictionsLeaderboard} />
                </div>
            </div>
        </div>
    );
};

export default PredictionsPage;
