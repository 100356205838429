import { FC, MouseEvent as ReactMouseEvent } from "react";
import { setKebabCase } from "../../../utils/string";
import Icon, { IconProps } from "../icon";
import Ripple from "../ripple";

import "./navItem.scss";

export interface NavItemProps {
    id: string;
    icon?: IconProps["icon"];
    label: string;
    selected?: boolean;
    classes?: string;
    onClick: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const NavItem: FC<NavItemProps> = ({ id, icon, label, selected = false, classes, onClick }) => {
    const navItemClasses = `navItemContainer ${selected ? "selected" : ""} ${classes ? classes : ""}`;

    return (
        <a id={id} href={`/${setKebabCase(id)}`} className={navItemClasses} onClick={onClick}>
            <div className="navItem">
                <div className="icon-container">{icon && <Icon icon={icon}></Icon>}</div>
                <div className="label">{label}</div>
                <Ripple></Ripple>
            </div>
        </a>
    );
};

export default NavItem;
