import { FC, useEffect, MouseEvent as ReactMouseEvent } from "react";
import { kebabCase } from "lodash";
import NavItem from "../../common/navItem";
import { history } from "../../../utils/history";
import { useLogin } from "../../../store/auth/useLogin";
import useWindowDimensions from "../../../utils/hooks/useWindowDimensions";
import { NAV_ITEMS as constants } from "../../../constants/global.constants";

import styles from "./nav.module.scss";

export interface NavProps {
    classes?: string;
}

export interface NavElementProps extends NavProps {
    onClick: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    selected: string;
}

const BrowserNav: FC<NavElementProps> = ({ onClick, selected, classes }) => {
    return (
        <div className={`${styles.nav} ${classes || ""}`}>
            <NavItem
                id={constants.Predictions}
                label={constants.Predictions}
                selected={selected.includes(constants.Predictions.toLocaleLowerCase())}
                onClick={onClick}
            />
            <NavItem
                id={constants.PredictionsTable}
                label={constants.PredictionsTable}
                selected={selected.includes(constants.PredictionsTable.toLocaleLowerCase())}
                onClick={onClick}
            ></NavItem>
            <NavItem
                id={constants.LOG_OUT}
                classes={styles.logOut}
                label={constants.LOG_OUT}
                onClick={onClick}
            ></NavItem>
        </div>
    );
};

const MobileNav: FC<NavElementProps> = ({ onClick }) => {
    return (
        <div className={styles.nav}>
            <NavItem id={constants.Predictions} label={constants.Predictions} onClick={onClick}></NavItem>
        </div>
    );
};

const Nav: FC<NavProps> = ({ ...rest }) => {
    const { logout } = useLogin();
    const { width } = useWindowDimensions();

    useEffect(() => {
        return () => {
            if (history.location.pathname === `/${kebabCase(constants.LOG_OUT)}`) logout();
        };
    }, [logout]);

    const handleClick = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        const { id } = event.currentTarget;

        const path = `/${kebabCase(id)}`;
        if (history.location.pathname !== path) history.push(path);
    };

    const selected = () => history.location.pathname.toLocaleLowerCase();

    return width >= 768 ? (
        <BrowserNav onClick={handleClick} selected={selected()} {...rest} />
    ) : (
        <MobileNav onClick={handleClick} selected={selected()} {...rest} />
    );
};

export default Nav;
