import { useEffect, useState } from "react";
import { predictionsService } from "../../services/predictions.service";

export interface Summary {
    currentPosition: number;
    averageScore: number;
}

export interface PredictionsSummary {
    data: Summary;
    success: boolean;
}

export interface UsePredictionsSummary {
    predictionsSummary: Summary | undefined;
    loading: boolean;
    error: any;
}

const usePredictionsSummary = (userId?: string): UsePredictionsSummary => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [predictionsSummary, setPredictionsSummary] = useState<Summary>();

    useEffect(() => {
        const getPredictionsSummary = async () => {
            if (!userId) return;

            setLoading(true);
            setError(false);

            try {
                const response = await predictionsService.getPredictionsSummary(userId);
                if (response?.data) setPredictionsSummary(response?.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };

        getPredictionsSummary();
    }, [userId]);

    return { predictionsSummary, loading, error };
};

export default usePredictionsSummary;
