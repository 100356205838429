import { forwardRef } from "react";

import "./title.scss";

export interface TitleProps {
    label: string;
    classes?: string;
}

const Title = forwardRef<HTMLDivElement, TitleProps>(({ label, classes }, ref) => (
    <div className={classes} ref={ref}>
        {label}
    </div>
));

export default Title;
