import { FC } from "react";
import { ToastContainer } from "react-toastify";

import "./toast.scss";

interface ToastProps {}

const Toast: FC<ToastProps> = () => {
    return <ToastContainer></ToastContainer>;
};

export default Toast;
