import { FC, useState } from "react";
import { omit } from "lodash";
import Input from "../common/input";
import Button from "../common/button";
import { useLogin } from "../../store/auth/useLogin";
import * as constants from "../../constants/login.constants";
import { validateInput } from "../../utils/validateInput";

import styles from "./login.module.scss";

export interface LoginProps {}

const Login: FC<LoginProps> = () => {
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [loginErrors, setloginErrors] = useState({});

    const { auth, login } = useLogin();

    const setData = (name: string, value: string): void => {
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    const getError = (prop: string): string => {
        const errors: { [index: string]: any } = loginErrors;
        if (errors[prop]) {
            return errors[prop]["message"];
        }
        if (prop === constants.PASSWORD && auth.error) {
            return auth.error?.data?.errors?.[0];
        }
        return "";
    };

    const setError = (prop: string, error: { [index: string]: any }): void => {
        if (error) {
            setloginErrors({ ...loginErrors, [prop]: error[0] });
        } else {
            setloginErrors(omit(loginErrors, [prop]));
        }
    };

    const setAllErrors = (validationErrors: Array<object>) => {
        const errors: { [index: string]: any } = {};

        if (validationErrors) {
            validationErrors.forEach((error: { [index: string]: any }) => {
                const prop = error["dataPath"].split("/").pop();
                errors[prop] = [error][0];
            });

            setloginErrors(errors);
        }
    };

    const handleBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
        const { id: name, value } = input;
        const error = validateInput(constants.loginSchema["properties"][name], value);
        setError(name, error);
    };

    const handleChange = ({ currentTarget: input }: { currentTarget: any }) => {
        const { id: name, value } = input;
        setData(name, value);
    };

    const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
        e.preventDefault();

        const errors = validateInput(constants.loginSchema, credentials);
        setAllErrors(errors);
        if (errors) return;
        const { email, password } = credentials;
        login(email, password);
    };

    return (
        <form className={styles.container}>
            <div className={styles.inputGroup}>
                <Input
                    id={constants.EMAIL}
                    label={constants.EMAIL}
                    value={credentials.email}
                    size={"small"}
                    autoComplete="on"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={getError(constants.EMAIL)}
                ></Input>
                <Input
                    id={constants.PASSWORD}
                    label={constants.PASSWORD}
                    type={constants.PASSWORD}
                    value={credentials.password}
                    size={"small"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={getError(constants.PASSWORD)}
                ></Input>
            </div>
            <Button
                name={"Login"}
                type={"submit"}
                onClick={handleSubmit}
                classes={`btn-black btn-rounded-sm ${styles.submit}`}
            ></Button>
        </form>
    );
};

export default Login;
